import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";

import { initialize, login, logout } from "../redux/actions/authActions";
import {
  setSavedQueries,
  setVisibleColumns,
} from "../redux/actions/orderActions";
import { setCsvMapping } from "../redux/actions/newOrderActions";
import setAuthToken from "../utils/setAuthToken";
import TableColumns from "../constants/TableColumns";

const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const initHandler = async () => {
    try {
      const jwtToken = window.localStorage.getItem("token");
      setAuthToken(jwtToken);

      if (jwtToken) {
        const res = await axios
          .get("/api/auth/current")
          .catch((err) => console.log("auth err: ", err));
        await getUserSettingsHandler();

        dispatch(
          initialize({
            isAuthenticated: true,
            user: res.data.user,
          })
        );
      } else {
        dispatch(
          initialize({
            isAuthenticated: false,
            user: null,
          })
        );
      }
    } catch (err) {
      dispatch(
        initialize({
          isAuthenticated: false,
          user: null,
        })
      );
      return;
    }
  };

  const loginHandler = async (email, password) => {
    axios
      .post("/api/auth/login", { email, password })
      .then((res) => {
        const { user, token } = res.data;

        if (token !== undefined) {
          localStorage.setItem("token", token);
          setAuthToken(token);
        }
        dispatch(login(user));
        window.location.href = "/orders";
      })
      .catch((err) => {
        toast.error(
          "Unable to Authenticate. Check your username and password."
        );
        return;
      });
  };

  const getUserSettingsHandler = () => {
    axios
      .get("/api/auth/user-settings")
      .then((res) => {
        const { savedCsvHeaders, queries, tableColumns } = res.data;
        if (savedCsvHeaders) {
          dispatch(setCsvMapping(savedCsvHeaders));
        }
        if (tableColumns.length) {
          dispatch(setVisibleColumns(tableColumns));
        } else {
          dispatch(setVisibleColumns(TableColumns));
        }
        if (queries) {
          dispatch(setSavedQueries(queries));
        }
      })
      .catch((err) => {
        console.log("**BeeP BooP** error getting user: ", err);
      });
  };

  const saveUserSettingsHandler = (settingName, newSettings) => {
    const body = {};
    body[settingName] = JSON.stringify(newSettings);

    axios
      .put("/api/auth/user-settings", body)
      .then(() => {
        toast.success("Successfully saved user settings!!");
        getUserSettingsHandler();
      })
      .catch((err) => console.log("*BEEP BOOOP* USER SETTINGS ERROR: ", err));
  };

  const logoutHandler = async () => {
    localStorage.removeItem("token");
    dispatch(logout());
  };

  return {
    ...auth,
    initHandler,
    loginHandler,
    getUserSettingsHandler,
    saveUserSettingsHandler,
    logoutHandler,
  };
};

export default useAuth;
