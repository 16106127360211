import {
  Dashboard,
  Assessment,
  ListAlt,
  MonetizationOn,
  Map,
} from "@mui/icons-material";

import DashboardPage from "../../pages/ReportsDashboard.js";
import TableList from "../../pages/InventoryReport.js";

const reportsRoutes = [
  {
    path: "/reports/dashboard",
    name: "Dashboard",
    icon: Assessment,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/reports/inventoryReport",
    name: "Inventory Mgmt Report",
    icon: Dashboard,
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/reports/orderReport",
    name: "Order Breakdown",
    icon: ListAlt,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/reports/codReport",
    name: "COD Report",
    icon: MonetizationOn,
    component: TableList,
  },
  {
    path: "/reports/overviewReport",
    name: "Overview",
    icon: Map,
    component: TableList,
  },
];

export default reportsRoutes;
