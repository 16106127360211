const JsSearch = require("js-search");

const orderSearching = (orders, searchQuery) => {
  const search = new JsSearch.Search("id");
  search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();

  if (searchQuery === null) {
    return orders;
  } else if (typeof searchQuery === "string") {
    search.addDocuments(orders);
    search.addIndex("id");
    search.addIndex(["consignee", "name"]);
    search.addIndex(["consignee", "location", "addressLine1"]);
    search.addIndex(["consignee", "location", "district"]);
    search.addIndex(["consignee", "location", "city"]);
    search.addIndex("details");
    search.addIndex("notes");
    search.addIndex(["consignee", "location", "contactPhone"]);
    search.addIndex("awb");
    search.addIndex("customerOrderNumber");
    return search.search(searchQuery);
  } else {
    let filtered = orders.slice();
    for (let key in searchQuery) {
      if (key === "status") {
        for (let searchTerm of searchQuery[key]) {
          filtered = filtered.filter((order) => order.status === searchTerm);
        }
      } else if (key === "rating") {
        for (let searchTerm of searchQuery[key]) {
          filtered = filtered.filter(
            (order) => order.deliveryInformation.consigneeRating === searchTerm
          );
        }
      } else if (key === "city") {
        for (let searchTerm of searchQuery[key]) {
          filtered = filtered.filter(
            (order) => order.consignee.location.city === searchTerm
          );
        }
      } else if (key === "district") {
        for (let searchTerm of searchQuery[key]) {
          filtered = filtered.filter(
            (order) => order.consignee.location.district === searchTerm
          );
        }
      } else if (key === "paymentMethod") {
        for (let searchTerm of searchQuery[key]) {
          filtered = filtered.filter(
            (order) => order.deliveryInformation.paymentMethod === searchTerm
          );
        }
      }
    }
    return filtered;
  }
};

export default orderSearching;
