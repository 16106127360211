import { Suspense, lazy } from "react";
import AuthGuard from "./components/Auth/AuthGuard";
import LoadingScreen from "./components/layout/LoadingScreen";
import ReportsLayout from "./layouts/ReportsLayout";
import LoginLayout from "./layouts/LoginLayout";
import MainLayout from "./layouts/MainLayout";
import PrintOrderLabel from "./pages/PrintOrderLabels";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Site pages
const LoginPage = Loadable(lazy(() => import("./pages/AuthPage")));
const OrdersPage = Loadable(lazy(() => import("./pages/OrdersPage")));
const ActivitiesPage = Loadable(lazy(() => import("./pages/ActivitiesPage")));
const NewOrdersPage = Loadable(lazy(() => import("./pages/NewOrdersPage")));
const ReportsDashboard = Loadable(
  lazy(() => import("./pages/ReportsDashboard"))
);
const InventoryReport = Loadable(lazy(() => import("./pages/InventoryReport")));
const OrderBreakdownReport = Loadable(
  lazy(() => import("./pages/OrderBreakdownReport"))
);
const ClientOverviewReport = Loadable(
  lazy(() => import("./pages/ClientOverviewReport"))
);
const CODReport = Loadable(lazy(() => import("./pages/CODReport")));

// Error pages
const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/errorPages/AuthorizationRequired"))
);
const CsvCorrections = Loadable(
  lazy(() => import("./components/NewOrders/CsvCorrections"))
);
const NotFound = Loadable(lazy(() => import("./pages/errorPages/NotFound")));
const ServerError = Loadable(
  lazy(() => import("./pages/errorPages/ServerError"))
);

const routes = [
  {
    path: "/",
    children: [
      {
        index: true,
        element: (
          <LoginLayout>
            <LoginPage />
          </LoginLayout>
        ),
      },
    ],
  },
  {
    path: "orders",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <OrdersPage />,
      },
    ],
  },
  {
    path: "print-order-labels",
    children: [
      {
        index: true,
        element: <PrintOrderLabel />,
      },
    ],
  },
  {
    path: "activities",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <ActivitiesPage />,
      },
    ],
  },
  {
    path: "new-orders",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <NewOrdersPage />,
      },
    ],
  },
  {
    path: "csvCorrections",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <CsvCorrections />,
      },
    ],
  },
  {
    path: "reports",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <ReportsLayout>
            <ReportsDashboard />
          </ReportsLayout>
        ),
      },
      {
        path: "inventoryReport",
        element: (
          <ReportsLayout>
            <InventoryReport />
          </ReportsLayout>
        ),
      },
      {
        path: "orderReport",
        element: (
          <ReportsLayout>
            <OrderBreakdownReport />
          </ReportsLayout>
        ),
      },
      {
        path: "codReport",
        element: (
          <ReportsLayout>
            <CODReport />
          </ReportsLayout>
        ),
      },
      {
        path: "overviewReport",
        element: (
          <ReportsLayout>
            <ClientOverviewReport />
          </ReportsLayout>
        ),
      },
    ],
  },
  {
    path: "*",
    children: [
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
