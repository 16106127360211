import LoginForm from "../components/Auth/LoginForm";

const Login = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
