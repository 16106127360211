import { styled } from "@mui/system";

import transcorpLogo from "../../assets/images/transcorpLogo.png";

const LogoImg = styled("img")`
  width: 180px;
`;

const Logo = (props) => (
  <LogoImg src={transcorpLogo} alt="transcorp logo" {...props} />
);

export default Logo;
