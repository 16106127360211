const columns = [
  {
    id: "id",
    disablePadding: false,
    label: "ID",
    width: 95,
    type: "number",
    csvHeading: false,
    required: false,
  },
  {
    id: "status",
    label: "Status",
    numeric: false,
    disablePadding: false,
    width: 140,
    type: "text",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryDate",
    label: "Delivery Date",
    disablePadding: false,
    width: 130,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "deliveryStartTime",
    label: "After Time",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "deliveryEndTime",
    label: "Before Time",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "consignee.name",
    key: "consignee.name",
    label: "Customer Name",
    disablePadding: false,
    width: 150,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "consignee.location.contactPhone",
    key: "consignee.location.contactPhone",
    label: "Customer Mobile",
    disablePadding: false,
    width: 150,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "consignee.location.addressLine1",
    key: "consignee.location.addressLine1",
    label: "Address",
    disablePadding: false,
    width: 200,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "consignee.location.district",
    key: "consignee.location.district",
    label: "Area",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "consignee.location.city",
    key: "consignee.location.city",
    label: "City",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "consignee.location.countryId",
    key: "consignee.location.countryId",
    label: "Country",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: true,
  },
  {
    id: "details",
    label: "Package Details",
    disablePadding: false,
    width: 150,
    type: "text",
    csvHeading: true,
    required: false,
  },
  {
    id: "codAmount",
    label: "COD Amount",
    disablePadding: false,
    width: 150,
    type: "text",
    csvHeading: true,
    required: false,
  },
  {
    id: "notes",
    label: "Delivery Notes",
    disablePadding: false,
    width: 150,
    type: "text",
    csvHeading: true,
    required: false,
  },
  {
    id: "customerOrderNumber",
    label: "Customer Order No",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: false,
  },

  {
    id: "deliveryInformation.bagsReturned",
    label: "Bags Returned",
    disablePadding: false,
    width: 100,
    type: "number",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.icePacksReturned",
    label: "Ice Packs Returned",
    disablePadding: false,
    width: 100,
    type: "number",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.numberOfAttempts",
    key: "deliveryInformation.numberOfAttempts",
    label: "Number of Attempts",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.deliveryEndTime",
    key: "deliveryInformation.deliveryEndTime",
    label: "Delivery End Time",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },

  {
    id: "smsNotifications",
    label: "SMS",
    disablePadding: false,
    width: 100,
    type: "bool",
    csvHeading: true,
    required: false,
  },
  {
    id: "deliveryType",
    label: "Delivery Type",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: false,
  },
  {
    id: "awb",
    label: "AWB",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: false,
  },
  {
    id: "volume",
    label: "Volume",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: false,
  },
  {
    id: "totalDeclaredGrossWeight",
    label: "Weight",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: false,
  },
  {
    id: "customer.name",
    key: "customer.name",
    label: "Customer",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },

  {
    id: "deliveryInformation.completionLatitude",
    key: "deliveryInformation.completionLatitude",
    label: "Completion Latitude",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.completionLongitude",
    key: "deliveryInformation.completionLongitude",
    label: "Completion Longitude",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.taskFailureReason",
    key: "deliveryInformation.taskFailureReason",
    label: "Failure Reason",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.reason",
    key: "deliveryInformation.reason",
    label: "Reason",
    disablePadding: false,
    width: 100,
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.consigneeRating",
    key: "deliveryInformation.consigneeRating",
    label: "Consignee Rating",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.consigneeComment",
    key: "deliveryInformation.consigneeComment",
    label: "Consignee Comment",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },
  {
    id: "consignee.latitude",
    key: "consignee.latitude",
    label: "Consignee Latitude",
    disablePadding: false,
    width: 95,
    type: "number",
    csvHeading: false,
    required: false,
  },
  {
    id: "consignee.longitude",
    key: "consignee.longitude",
    label: "Consignee Longitude",
    disablePadding: false,
    width: 95,
    type: "number",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.paymentMethod",
    key: "deliveryInformation.paymentMethod",
    label: "Mode of Payment",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: true,
    required: false,
  },
  {
    id: "deliveryInformation.collectedAmount",
    key: "deliveryInformation.collectedAmount",
    label: "Collected Amount",
    disablePadding: false,
    width: 100,
    type: "text",
    csvHeading: false,
    required: false,
  },
  {
    id: "deliveryInformation.id",
    key: "deliveryInformation.id",
    disablePadding: false,
    label: "Delivery ID",
    width: 95,
    type: "number",
    csvHeading: false,
    required: false,
  },
  {
    id: "hangers",
    disablePadding: false,
    label: "Hangers",
    width: 95,
    type: "number",
    csvHeading: true,
    required: false,
  },
  {
    id: "totalShipmentQuantity",
    disablePadding: false,
    label: "Shipment Quantity",
    width: 95,
    type: "number",
    csvHeading: true,
    required: true,
  },
  {
    id: "type",
    disablePadding: false,
    label: "Order Type",
    width: 95,
    type: "text",
    csvHeading: true,
    required: false,
  },
];
export default columns;
