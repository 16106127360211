import { combineReducers } from "redux";

import authReducer from "./authReducer";
import activityReducer from "./activityReducer";
import newOrderReducer from "./newOrderReducer";
import orderReducer from "./orderReducer";
import reportsReducer from "./reportsReducer";

export default combineReducers({
  auth: authReducer,
  activity: activityReducer,
  newOrders: newOrderReducer,
  orders: orderReducer,
  reports: reportsReducer,
});
