import * as React from "react";
import { NavLink as UnstyledLink } from "react-router-dom";
import { styled } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Logo from "./Logo";
import useAuth from "../../hooks/useAuth";

const ResponsiveAppBar = () => {
  const { user, logoutHandler } = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{ boxShadow: "none", borderBottom: "solid 1px #ddd" }}
    >
      <Container maxWidth="100%" sx={{ background: "#fff" }}>
        <Toolbar disableGutters>
          <Logo sx={{ display: { xs: "none", sm: "flex" } }} />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <NavLink to="/orders">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Orders</Typography>
                </MenuItem>
              </NavLink>
              <NavLink to="/activities">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Activities</Typography>
                </MenuItem>
              </NavLink>
              <NavLink to="/orders/new">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">New Orders</Typography>
                </MenuItem>
              </NavLink>
              <NavLink to="/reports/dashboard">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">Reports</Typography>
                </MenuItem>
              </NavLink>
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <NavLink to="/orders">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#111", display: "block" }}
              >
                Orders
              </Button>
            </NavLink>
            <NavLink to="/activities">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#111", display: "block" }}
              >
                Activities
              </Button>
            </NavLink>
            <NavLink to="/new-orders">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#111", display: "block" }}
              >
                New Orders
              </Button>
            </NavLink>
            <NavLink to="/reports/dashboard">
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "#111", display: "block" }}
              >
                Reports
              </Button>
            </NavLink>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{
                  p: 0,
                  fontWeight: "600",
                  fontFamily:
                    '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                  fontSize: "0.875rem",
                  color: "#111",
                  display: "flex",
                  gap: "10px",
                  borderRadius: "3px",
                }}
              >
                {user && user.name ? user.name : ""}
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={logoutHandler}>
                <Typography textAlign="center">Log Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const NavLink = styled(UnstyledLink)`
  text-decoration: none;
  margin-left: 15px;

  &.active {
    button {
      color: #0e8ccf;
    }
  }
`;

export default ResponsiveAppBar;
