import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { createCustomTheme } from "./theme";

import SplashScreen from "./components/layout/SplashScreen";
import RTL from "./components/layout/RTL";
import useSettings from "./hooks/useSettings";
import useAuth from "./hooks/useAuth";
import routes from "./routes";

function App() {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  useEffect(() => {
    auth.initHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RTL direction={settings.direction}>
      <Toaster
        position="top-center"
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "#fff",
              fontSize: "24px",
              padding: "20px 30px",
              minWidth: "620px",
            },
          },
          error: {
            style: {
              background: "red",
              color: "#fff",
              fontSize: "24px",
              padding: "20px 30px",
              minWidth: "620px",
            },
            loading: {
              style: {
                background: "lightsalmon",
                color: "#fff",
                fontSize: "24px",
                padding: "20px 30px",
                minWidth: "620px",
              },
            },
          },
        }}
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {auth.isInitialized ? content : <SplashScreen />}
      </ThemeProvider>
    </RTL>
  );
}

export default App;
