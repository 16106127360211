import React from "react";

import Sidebar from "../components/Reports/components/Sidebar";
import reports from "../components/Reports/reportRoutes";

const Reports = ({ children }) => {
  return (
    <>
      <Sidebar color={"blue"} reports={reports} />
      <div
        style={{
          margin: "10px 0 30px 278px",
          width: "80%",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Reports;
