import { formatDate } from "../../helpers/index";

import {
  ADD_FILTER,
  CLOSE_ORDER_MODAL,
  SET_CSV_HEADERS,
  SET_TIMELINE,
  SET_DATE_RANGE,
  SET_ALL_ORDERS,
  SET_TABLE_DENSE,
  ADD_SEARCH_QUERY,
  SET_SELECTED_ORDER,
  SET_PENDING_ORDERS,
  SET_SHOWING_ORDERS,
  SET_VISIBLE_COLUMNS,
  SET_COMPLETE_ORDERS,
  SHOW_SELECTED_ORDER,
  SHOW_CREATE_ORDER_MODAL,
  SET_SAVED_QUERIES,
  SET_LOADING,
} from "../actions/types";

const initialState = {
  loading: false,
  allOrders: [],
  pendingOrders: [],
  completeOrders: [],
  showingOrders: [],
  savedQueries: [],
  savedFilters: [],
  tableDense: false,
  currentDateRange: [formatDate(Date.now()), formatDate(Date.now())],
  customerHeaders: [],
  showSelectedOrderModal: false,
  selectedOrder: {
    id: "",
    consignee: {
      name: "",
      location: {
        contactPhone: "",
        addressLine1: "",
        city: "",
      },
    },
    shipFrom: {
      name: "",
      phone: "",
      addressLine1: "",
      city: "",
      district: "",
      countryCode: "224",
    },
    hangers: "",
    deliveryDate: formatDate(Date.now()),
    deliveryStartTime: "",
    deliveryEndTime: "",
    details: "",
    totalShipmentQuantity: "",
    totalDeclaredGrossWeight: "",
    volume: "",
    customerOrderNumber: "",
    codAmount: "",
    deliveryInformation: {
      paymentMethod: "",
    },
    deliveryType: "",
    type: "",
    smsNotifications: "",
    notes: "",
  },
  searchValue: "",
  visibleColumns: [],
  timeline: [],
  csvHeaders: [],
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_ORDERS:
      return {
        ...state,
        allOrders: action.payload,
      };

    case SET_DATE_RANGE:
      return {
        ...state,
        currentDateRange: action.payload,
      };

    case SET_VISIBLE_COLUMNS:
      return {
        ...state,
        visibleColumns: action.payload,
      };

    case SET_SAVED_QUERIES:
      return {
        ...state,
        savedQueries: action.payload,
      };

    case SET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: action.payload,
      };

    case SET_COMPLETE_ORDERS:
      return {
        ...state,
        completeOrders: action.payload,
      };

    case SET_SHOWING_ORDERS:
      return {
        ...state,
        showingOrders: action.payload,
      };

    case SET_TABLE_DENSE:
      return {
        ...state,
        tableDense: !state.tableDense,
      };

    case SET_TIMELINE:
      return {
        ...state,
        timeline: action.payload,
      };

    case SET_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder:
          action.payload === null ? initialState.selectedOrder : action.payload,
      };

    case SET_CSV_HEADERS:
      return {
        ...state,
        csvHeaders: action.payload,
      };

    case SHOW_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: action.payload,
        showSelectedOrderModal: true,
      };

    case SHOW_CREATE_ORDER_MODAL:
      return {
        ...state,
        showSelectedOrderModal: true,
      };

    case ADD_SEARCH_QUERY:
      return {
        ...state,
        searchQueries: [...state.searchQueries, action.payload],
      };

    case ADD_FILTER:
      return {
        ...state,
        savedFilters: [...state.savedFilters, action.payload],
      };

    case CLOSE_ORDER_MODAL:
      return {
        ...state,
        csvMappingShowModal: false,
        createShow: false,
        showSelectedOrderModal: false,
        selectedOrder: initialState.initialState,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
