import {
  CLOSE_NEW_ORDER_MODAL,
  SET_ORDER_EDITS,
  SET_ORDER_UPLOADS,
  SHOW_CSVMAP_MODAL,
  SET_FORM_IS_EDITABLE,
  SHOW_CREATE_ORDER_MODAL,
  SET_CSV_MAPPING_ERRORS,
  SET_CSV_REQUEST_PENDING,
  SET_USED_CSV_HEADER_NAMES,
  SET_CSV_MAPPING,
  SET_FORM_ERRORS,
  SET_CSV_ERRORS,
  SET_CSV_HEADER_ERRORS,
} from "./types";

export const setOrderEdits = (data) => {
  return {
    type: SET_ORDER_EDITS,
    payload: data,
  };
};

export const setFormIsEditable = (data) => {
  return {
    type: SET_FORM_IS_EDITABLE,
    payload: data,
  };
};

export const setOrderUploads = (data) => {
  return {
    type: SET_ORDER_UPLOADS,
    payload: data,
  };
};

export const openCsvModal = (customerHeadings) => {
  return {
    type: SHOW_CSVMAP_MODAL,
    payload: customerHeadings,
  };
};

export const setCsvHeaderErrors = (data) => {
  return {
    type: SET_CSV_HEADER_ERRORS,
    payload: data,
  };
};

export const setCsvMapping = (data) => {
  return {
    type: SET_CSV_MAPPING,
    payload: data,
  };
};

export const setUsedCsvHeaderNames = (data) => {
  return {
    type: SET_USED_CSV_HEADER_NAMES,
    payload: data,
  };
};

export const setCsvRequestPending = (data) => {
  return {
    type: SET_CSV_REQUEST_PENDING,
    payload: data,
  };
};

export const setCsvMappingErrors = (data) => {
  return {
    type: SET_CSV_MAPPING_ERRORS,
    payload: data,
  };
};

export const setCsvErrors = (data) => {
  return {
    type: SET_CSV_ERRORS,
    payload: data,
  };
};

export const openCreateModal = () => {
  return {
    type: SHOW_CREATE_ORDER_MODAL,
  };
};

export const setFormErrors = (data) => {
  return {
    type: SET_FORM_ERRORS,
    payload: data,
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_NEW_ORDER_MODAL,
  };
};
