// activity

import { INITIALIZE, LOGIN, LOGOUT, SET_USER_SETTINGS } from "../actions/types";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };

    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case SET_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
}
