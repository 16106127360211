/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { QRCodeSVG } from "qrcode.react";
import { formatTime } from "../helpers";

import { Button, FormControlLabel, FormGroup, Radio } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

import LabelHead from "../assets/images/tcorpLabelLogo.png";
import largeLogo from "../assets/images/transcorpLogo.png";
import useAuth from "../hooks/useAuth";
// import Barcode from "../components/PrintOrderLabels/Barcode";
import Barcode from "react-barcode";

const stickerLabel = {
  // width: "31%",
  height: "90.5mm",
  // border: '1px solid #555',
  // marginBottom: '.5rem',
  // border: '1px solid #555',
  marginRight: "4.6mm",
  boxSizing: "border-box",
};

const stickerText = {
  color: "#000",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
  fontSize: "16px",
};

const lastStickerLabelRow = {
  width: "100%",
  height: "18%",
  display: "flex",
  justifyContent: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};

const stickerPageBreak = {
  height: "4.3mm",
  // backgroundColor: 'red',
  width: "100%",
  pageBreakBefore: "auto",
  display: "block",
  marginBottom: "4.875mm",
};
const printStickerContainer = {
  // width: '210mm',
  // height: '297mm',
  boxSizing: "border-box",
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
  marginTop: "4.875mm",
};
const tcorpStickerLCol = {
  width: "50%",
  textAlign: "center",
  // borderTop: '1px solid #555',
  boxSizing: "border-box",
};

const tcorpStickerRCol = {
  width: "50%",
  textAlign: "center",
  // borderTop: '1px solid #555',
  boxSizing: "border-box",
};
const tcorpStickerRow = {
  width: "100%",
  height: "8%",
  display: "flex",
  justifyContent: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};
const tcorpStickerNameRow = {
  width: "100%",
  height: "8%",
  display: "flex",
  justifyContent: "center",
  // borderTop: '1px solid #555',
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};
const tcorpStickerCityCol = {
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // borderBottom: '1px solid #555',
  boxSizing: "border-box",
};

const tcorpStickerAreaCol = {
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // borderBottom: '1px solid #555',
  boxSizing: "border-box",
};

// ////////////////////
// small label styling
const tcorpLabel = {
  width: "6.8cm",
  height: "7.6cm",
  border: "1px solid #555",
  marginBottom: "2mm",
  marginRight: "2mm",
  boxSizing: "border-box",
};

const tcorpLabelLCol = {
  width: "50%",
  textAlign: "center",
  border: "1px solid #555",
  boxSizing: "border-box",
};

const tcorpLabelRCol = {
  width: "50%",
  textAlign: "center",
  border: "1px solid #555",
  boxSizing: "border-box",
};
const tcorpLabelCityCol = {
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "2.5rem",
  border: "1px solid #555",
  boxSizing: "border-box",
};

const tcorpLabelAreaCol = {
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "2.5rem",
  border: "1px solid #555",
  boxSizing: "border-box",
};

const lastLabelRow = {
  width: "100%",
  height: "16%",
  display: "flex",
  justifyContent: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};
const tcorpLabelRow = {
  width: "100%",
  height: "8%",
  display: "flex",
  justifyContent: "center",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};
const tcorpNameRow = {
  width: "100%",
  height: "8%",
  display: "flex",
  justifyContent: "center",
  border: "1px solid #555",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};

const barcode = {
  margin: "3px auto",
  paddingTop: "3px",
  height: "42%",
  textAlign: "center",
};

const barcodeDiv = {
  border: "1px solid #555",
};

const printContainer = {
  margin: "20mm",
  width: "21cm",
  height: "29.7cm",
  boxSizing: "border-box",
  display: "flex",
  flexWrap: "wrap",
  alignContent: "flex-start",
};

const blackText = {
  color: "#000",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};

const nameText = {
  color: "#000",
  fontWeight: "bold",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};

const logoImage = {
  maxWidth: "100px",
  width: "100px",
  boxSizing: "border-box",
};

const pageBreak = {
  height: "39.3mm",
  marginBottom: "20mm",
  // backgroundColor: 'red',
  width: "100%",
  pageBreakBefore: "auto",
  display: "block",
};
// ////////////////////
// large label styling
const largeLabel = {
  width: "10.2cm",
  height: "30.4cm",
  border: "1px solid #555",
  marginBottom: ".5rem",
  marginRight: "1.7mm",
  boxSizing: "border-box",
};

const largeLabelRow = {
  width: "100%",
  height: "16%",
  display: "flex",
  overflow: "hidden",
  boxSizing: "border-box",
  border: "1px solid black",
  padding: "10px",
};

const largeLabelLogoRow = {
  width: "100%",
  height: "16%",
  textAlign: "center",
  overflow: "hidden",
  boxSizing: "border-box",
  border: "1px solid black",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const largeLogoImage = {
  maxWidth: "300px",
  width: "300px",
};

const largeLabelBarcodeRow = {
  width: "100%",
  height: "16%",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
  boxSizing: "border-box",
  border: "1px solid black",
  padding: "10px",
};

const largeLabelQrcodeRow = {
  width: "100%",
  height: "20%",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
  boxSizing: "border-box",
  border: "1px solid black",
  padding: "10px",
};

const largeLabelQrcode = {
  paddingTop: "3px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
};

const largeText = {
  color: "#000",
  fontSize: "20px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
};

const largeHeadText = {
  color: "#000",
  fontSize: "15px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  boxSizing: "border-box",
  lineHeight: "1.9",
};

const largeWrapText = {
  color: "#000",
  fontSize: "20px",
  overflow: "hidden",
  boxSizing: "border-box",
};

const largeBoldText = {
  color: "#000",
  fontSize: "22px",
  fontWeight: "bold",
  whiteSpace: "wrap",
  overflow: "hidden",
  boxSizing: "border-box",
};

const largePageBreak = {
  height: "45.1mm",
  marginBottom: "20mm",
  // backgroundColor: 'red',
  width: "100%",
  pageBreakBefore: "auto",
  display: "block",
};

// labelSize false === small labels
const PrintOrderLabel = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [state, setState] = useState({
    orders: [],
    labelSize: "Small",
  });
  const toPrint = useRef();

  useEffect(() => {
    const { orders, selected } = location.state;
    const ordersToPrint = [];
    if (selected) {
      selected.forEach((select) => {
        orders.forEach((order) => {
          if (order.id === select) {
            return ordersToPrint.push(order);
          }
        });
      });
      setState({
        ...state,
        orders: ordersToPrint,
      });
    }
  }, []);

  const handleLabelSize = (e) => {
    setState({
      ...state,
      labelSize: e.target.value,
    });
  };
  const { orders } = state;
  let count = 0;

  let label =
    state.labelSize === null ? (
      <div className="tcorp-print-container">
        <div ref={toPrint} style={printContainer}></div>
      </div>
    ) : state.labelSize === "Large" ? (
      <div className="tcorp-print-container">
        <div ref={toPrint} style={printContainer}>
          {orders &&
            orders.map((order, index) => (
              <div key={index}>
                {order.shipmentPackages.map((shipmentPackage, i) => (
                  <div key={"box" + i}>
                    <div className="tcorp-label" style={largeLabel}>
                      {/* top row */}
                      <div className="label-card" style={largeLabelLogoRow}>
                        {/* logo */}
                        <div>
                          <img
                            style={largeLogoImage}
                            src={largeLogo}
                            alt="Transcorp Label Header"
                          />
                        </div>

                        <div style={largeText}>
                          Delivery Date: {order.deliveryDate}
                        </div>
                        <div style={largeText}>
                          Deliver After:
                          {formatTime(order.deliveryStartTime, "HH:mm")}
                        </div>
                        <div style={largeText}>
                          Deliver Before:
                          {formatTime(order.deliveryEndTime, "HH:mm")}
                        </div>
                      </div>

                      {/* FROM: */}
                      <div style={largeLabelRow}>
                        <div style={{ width: "19%" }}>
                          <span style={largeHeadText}>From: </span>
                        </div>
                        <div style={{ width: "81%" }}>
                          <div style={largeText}>{order.shipFrom.name}</div>
                        </div>
                      </div>

                      {/* TO: */}
                      <div style={largeLabelRow}>
                        <div style={{ width: "19%" }}>
                          <span style={largeHeadText}>To:</span>
                        </div>
                        <div style={{ width: "81%" }}>
                          <div style={largeBoldText}>
                            {order.consignee.name}
                          </div>
                          <div style={largeWrapText}>
                            {order.consignee.location.addressLine1}
                          </div>
                          <div style={largeText}>
                            {order.consignee.location.district}
                          </div>
                          <div style={largeText}>
                            {order.consignee.location.city}
                          </div>
                          <div style={largeText}>
                            {order.consignee.location.countryId}
                          </div>
                        </div>
                      </div>

                      {/* order number and number of boxes */}
                      <div style={largeLabelRow}>
                        {/* Reference */}
                        <div style={{ width: "19%" }}>
                          <div style={largeHeadText}>Order No:</div>
                          <div style={largeHeadText}>Code:</div>
                          <div style={largeHeadText}>Boxes:</div>
                          <div style={largeHeadText}>Hangers:</div>
                          <div style={largeHeadText}>Contents:</div>
                        </div>

                        <div style={{ width: "81%" }}>
                          <div style={largeText}>
                            {order.customerOrderNumber || "na"}
                          </div>
                          <div style={largeText}>na</div>
                          <div style={largeText}>
                            {i + 1} of {order.totalShipmentQuantity}
                          </div>
                          <div style={largeText}>{order.hangers || "na"}</div>
                          <div style={largeWrapText}>
                            {order.details || "na"}
                          </div>
                        </div>
                      </div>

                      <div style={largeLabelQrcodeRow}>
                        {/* QR Code */}
                        <div style={largeLabelQrcode}>
                          <QRCodeSVG
                            size={200}
                            value={shipmentPackage.trackingId}
                          />
                          <span style={blackText}>
                            {shipmentPackage.trackingId}
                          </span>
                        </div>
                      </div>

                      <div style={largeLabelBarcodeRow}>
                        {/* barcode */}
                        <div style={largeLabelQrcode}>
                          <Barcode value={shipmentPackage.trackingId} />
                        </div>
                      </div>
                    </div>
                    {(count = count + 1) % 2 === 0 && (
                      <div className="page-break" style={largePageBreak} />
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    ) : state.labelSize === "Small" ? (
      <div className="tcorp-print-container">
        <div ref={toPrint} style={printContainer}>
          {orders &&
            orders.map((order, index) => (
              <div key={index}>
                {order.shipmentPackages.map((shipmentPackage, i) => (
                  <div key={"box" + i}>
                    <div className="tcorp-label" style={tcorpLabel}>
                      {/* <div className="label-card" key={i}> */}
                      <div className="label-card" style={tcorpLabelRow}>
                        {/* logo */}
                        <div style={tcorpLabelLCol}>
                          <img
                            style={logoImage}
                            src={LabelHead}
                            alt="Transcorp Label Header"
                          />
                        </div>
                        {/* data and time */}
                        <div style={tcorpLabelRCol}>
                          <span style={blackText}>
                            {order.deliveryDate} -&nbsp;
                            {formatTime(order.deliveryEndTime, "HH:mm")}
                          </span>
                        </div>
                      </div>

                      {/* Merchant Name */}
                      <div style={tcorpNameRow}>
                        <span style={blackText}>
                          FROM: {order.shipFrom.name}
                        </span>
                      </div>
                      <div style={barcodeDiv}>
                        {/* QR Code */}
                        <div style={barcode}>
                          <QRCodeSVG
                            size={120}
                            value={shipmentPackage.trackingId}
                          />
                        </div>

                        <div style={tcorpLabelRow}>
                          {/* AWB */}
                          <span style={blackText}>
                            {shipmentPackage.trackingId}
                          </span>
                        </div>
                      </div>
                      <div style={tcorpNameRow}>
                        {/* Customer Name */}
                        <span style={blackText}>TO:&nbsp;</span>
                        <span style={nameText}>{order.consignee.name}</span>
                      </div>
                      <div style={tcorpLabelRow}>
                        {/* No. Boxes */}
                        <div style={tcorpLabelLCol}>
                          <span style={blackText}>
                            Box {i + 1} of {order.totalShipmentQuantity}
                          </span>
                        </div>
                        {/* City */}
                        <div style={tcorpLabelRCol}>
                          <span style={blackText}>
                            {order.consignee.location.city}
                          </span>
                        </div>
                      </div>
                      <div style={lastLabelRow}>
                        {/* Reference */}
                        <div style={tcorpLabelCityCol}>
                          <span style={blackText}>
                            {order.customerOrderNumber}
                          </span>
                        </div>
                        {/* Area/District */}
                        <div style={tcorpLabelAreaCol}>
                          <span style={blackText}>
                            {order.consignee.location.district}
                          </span>
                        </div>
                      </div>
                    </div>
                    {(count = count + 1) % 12 === 0 && (
                      <div className="page-break" style={pageBreak} />
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    ) : (
      <div className="print-selection">
        <div ref={toPrint} style={printStickerContainer}>
          {orders &&
            orders.map((order, index) => (
              <div key={index}>
                {order.shipmentPackages.map((shipmentPackage, i) => (
                  <div key={"box" + i}>
                    <div className="tcorp-label" style={stickerLabel}>
                      <div className="label-card" style={tcorpStickerRow}>
                        {/* logo */}
                        <div style={tcorpStickerLCol}>
                          <img
                            style={logoImage}
                            src={LabelHead}
                            alt="Transcorp Label Header"
                          />
                        </div>
                        {/* data and time */}
                        <div style={tcorpStickerRCol}>
                          <span style={blackText}>
                            {order.deliveryDate}
                            &nbsp;
                            {formatTime(order.deliveryEndTime, "HH:mm")}
                          </span>
                        </div>
                      </div>

                      {/* Merchant Name */}
                      <div style={tcorpStickerNameRow}>
                        <span style={blackText}>
                          FROM: {order.shipFrom.name}
                        </span>
                      </div>
                      <div>
                        {/* QR Code */}
                        <div style={barcode}>
                          <QRCodeSVG
                            size={120}
                            value={shipmentPackage.trackingId}
                          />
                        </div>

                        <div style={tcorpStickerRow}>
                          {/* AWB */}
                          <span style={blackText}>
                            {shipmentPackage.trackingId}
                          </span>
                        </div>
                      </div>
                      <div style={tcorpStickerNameRow}>
                        {/* Customer Name */}
                        <span style={blackText}>TO:&nbsp;</span>
                        <span style={nameText}>{order.consignee.name}</span>
                      </div>
                      <div style={tcorpStickerRow}>
                        {/* No. Boxes */}
                        <div style={tcorpStickerLCol}>
                          <span style={stickerText}>
                            Box {i + 1} of {order.totalShipmentQuantity}
                          </span>
                        </div>
                        {/* City */}
                        <div style={tcorpStickerRCol}>
                          <span style={stickerText}>
                            {order.consignee.location.city}
                          </span>
                        </div>
                      </div>
                      <div style={lastStickerLabelRow}>
                        {/* Reference */}
                        <div style={tcorpStickerCityCol}>
                          <span style={stickerText}>
                            {order.customerOrderNumber}
                          </span>
                        </div>
                        {/* Area/District */}
                        <div style={tcorpStickerAreaCol}>
                          <span style={stickerText}>
                            {order.consignee.location.district}
                          </span>
                        </div>
                      </div>
                    </div>
                    {(count = count + 1) % 12 === 0 && (
                      <div className="page-break" style={stickerPageBreak} />
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    );

  return (
    <div className="print-container-scroll">
      <div style={{ margin: "0 auto", width: "fit-content" }}>
        <div className="print-nav">
          <div>
            <Button
              title="back"
              type="button"
              variant="contained"
              sx={{ margin: "10px 10px 10px 0" }}
              onClick={() => navigate(-1)}
            >
              &nbsp;Back
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button
                  title="Print Table"
                  type="button"
                  variant="contained"
                  color="primary"
                  sx={{ margin: "10px 10px 10px 0" }}
                >
                  <PrintIcon />
                  &nbsp;Print Labels
                </Button>
              )}
              content={() => toPrint.current}
              pageStyle="@page { size: A4; margin: 0mm; } @media print { body { width: 210mm !important; height: 297mm !important; -webkit-print-color-adjust: exact; padding: 0px !important; } }"
              copyStyles
            />
          </div>

          <FormGroup style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              control={
                <Radio
                  checked={state.labelSize === "Stickers"}
                  onChange={handleLabelSize}
                  value="Stickers"
                  color="primary"
                  name="labelSize"
                />
              }
              label={"Stickers"}
            />

            <FormControlLabel
              control={
                <Radio
                  checked={state.labelSize === "Small"}
                  onChange={handleLabelSize}
                  value="Small"
                  color="primary"
                  name="labelSize"
                />
              }
              label={"Small"}
            />
            <FormControlLabel
              control={
                <Radio
                  checked={state.labelSize === "Large"}
                  onChange={handleLabelSize}
                  value="Large"
                  color="primary"
                  name="labelSize"
                  size="small"
                />
              }
              label={"Large"}
            />
          </FormGroup>
        </div>
        {label}
      </div>
    </div>
  );
};

export default PrintOrderLabel;

// click check boxes and then click print
// handlePrinting send the orders to be printed to the print component
// the print component will call print for number of boxes and label number of boxes i.e. 1 of 3
