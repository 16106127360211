/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ChartistGraph from "react-chartist";

import { makeStyles } from "@mui/styles";
import {
  AccessTime,
  FiberNew,
  Update,
  LocalOffer,
  DateRange,
  Warning,
  LocalShipping,
  ErrorOutline,
  LocalAtm,
} from "@mui/icons-material";

import GridItem from "../components/Reports/components/GridItem.js";
import GridContainer from "../components/Reports/components/GridContainer.js";
import Danger from "../components/Reports/components/Danger.js";
import Card from "../components/Reports/components/Card.js";
import CardHeader from "../components/Reports/components/CardHeader.js";
import CardIcon from "../components/Reports/components/CardIcon.js";
import CardBody from "../components/Reports/components/CardBody.js";
import CardFooter from "../components/Reports/components/CardFooter.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "../components/Reports/variables/charts.js";
import styles from "../assets/jss/material-dashboard-react/components/dashboardStyle.js";
import CustomDatePicker from "../components/Orders/OrdersTableNav/CustomDatePicker";
import useOrders from "../hooks/useOrders";

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const { allOrders, getOrders } = useOrders();

  const classes = useStyles();
  const [chartData, setChartData] = useState({
    totalPackages: 0,
    completedDeliveries: 0,
    failedDeliveries: 0,
    cods: 0,
    deliveriesChart: {
      // labels: [],
      series: [],
    },
    packagesChart: {
      // labels: [],
      series: [],
    },
    codsChart: {
      // labels: [],
      series: [],
    },
  });

  useEffect(() => {
    if (allOrders.length === 0) {
      getOrders();
    }
  }, []);

  useEffect(() => {
    const data = generateChartData();
    setChartData(data);
  }, [props]);

  const generateChartData = () => {
    const data = {
      totalPackages: 0,
      completedDeliveries: 0,
      failedDeliveries: 0,
      cods: 0,
      deliveriesChart: {
        // labels: [],
        series: [],
      },
      packagesChart: {
        // labels: [],
        series: [],
      },
      codsChart: {
        // labels: [],
        series: [],
      },
    };

    const dates = {};
    allOrders.forEach((order) => {
      const date = order.deliveryDate;
      if (!dates[date]) {
        dates[date] = [0, 0, 0];
      }
      // total quanity of packages shipped
      if (order.totalShipmentQuantity) {
        const qtyAmount = Number(order.totalShipmentQuantity);
        data.totalPackages += qtyAmount;
        dates[date][0] += qtyAmount;
      }
      // cash on delivery
      if (order.codAmount) {
        const codAmount = Number(
          order.deliveryInformation.collectedAmount || 0
        );
        data.cods += codAmount;
        dates[date][2] += codAmount;
      }
      // completed orders
      if (order.status === "DELIVERED") {
        data.completedDeliveries++;
        dates[date][1]++;
      }
      // failed orders
      if (order.status === "FAILED") {
        data.failedDeliveries++;
      }
    });
    const keys = Object.keys(dates);
    const vals = Object.values(dates);
    const dataByDate = [];

    keys.forEach((date, i) => {
      dataByDate.push([date, ...vals[i]]);
    });
    dataByDate.sort((a, b) => {
      return Date.parse(a[0]) - Date.parse(b[0]);
    });
    const deliveriesTemp = [];
    const packagesTemp = [];
    const codTemp = [];
    dataByDate.forEach((date, i) => {
      // data.deliveriesChart.labels.push(date[0]);
      deliveriesTemp.push(date[2]);
      // data.packagesChart.labels.push(date[0]);
      packagesTemp.push(date[1]);
      // data.codsChart.labels.push(date[0]);
      codTemp.push(date[3]);
    });
    data.codsChart.series.push(codTemp);
    data.deliveriesChart.series.push(deliveriesTemp);
    data.packagesChart.series.push(packagesTemp);
    return data;
  };

  return (
    <>
      <div style={{ marginLeft: "-10px", marginBottom: "15px" }}>
        <CustomDatePicker />
      </div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <FiberNew />
              </CardIcon>
              <p className={classes.cardCategory}>Total Packages</p>
              <h3 className={classes.cardTitle}>
                {chartData.totalPackages} <small>pcs</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="/new-orders" style={{ paddingTop: "3px" }}>
                  Place a new order
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <LocalShipping />
              </CardIcon>
              <p className={classes.cardCategory}>Completed Deliveries</p>
              <h3 className={classes.cardTitle}>
                {chartData.completedDeliveries}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Since 2020-11-01
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <ErrorOutline />
              </CardIcon>
              <p className={classes.cardCategory}>Failed Deliveries</p>
              <h3 className={classes.cardTitle}>
                {chartData.failedDeliveries}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <LocalAtm />
              </CardIcon>
              <p className={classes.cardCategory}>Cash On Delivery</p>
              <h3 className={classes.cardTitle}>{chartData.cods.toFixed(2)}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart"
                data={chartData.packagesChart}
                type="Line"
                options={{
                  low: 0,
                  high: Math.max.apply(Math, chartData.packagesChart[0]) + 2,
                }}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Packages</h4>
              <p className={classes.cardCategory}>Deliveries this week.</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Check the Overview report for more details
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={chartData.deliveriesChart}
                type="Line"
                options={{
                  low: 0,
                  high: Math.max.apply(Math, chartData.deliveriesChart[0]) + 2,
                }}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Deliveries this week</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Check the Overview report for more details
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={chartData.codsChart}
                type="Bar"
                options={{
                  low: 0,
                  high: 1000,
                }}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>CODs</h4>
              <p className={classes.cardCategory}>
                Cash Recieved Per Day this Week
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Check the COD Report for more details
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default Dashboard;
