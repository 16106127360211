// activity

import { SET_ACTIVITY_LOADING, GET_ACTIVITIES } from "../actions/types";

const initialState = {
  activities: { activities: {}, loading: true },
};

export default function activityReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVITY_LOADING:
      return {
        ...state,
        activities: { loading: true },
      };

    case GET_ACTIVITIES:
      return {
        ...state,
        activities: { activities: action.payload, loading: false },
      };

    default:
      return state;
  }
}
