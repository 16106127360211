/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";

import {
  setAllOrders,
  setPendingOrders,
  setCompleteOrders,
  setShowingOrders,
  setVisibleColumns,
  setDateRange,
  setShowSelectedOrder,
  setTableDense,
  setTimeline,
  closeModal,
  setSelectedOrder,
  setLoading,
  setCsvHeaders,
} from "../redux/actions/orderActions";
import orderSearching from "../utils/orderSearching";
import TableColumns from "../constants/TableColumns";
import { formatDate } from "../helpers/index";

const orderInitialState = {
  consignee: {
    name: "",
    location: {
      contactPhone: "",
      addressLine1: "",
      city: "",
    },
  },
  shipFrom: {
    name: "",
    phone: "",
    addressLine1: "",
    city: "",
    district: "",
    countryId: 224,
  },
  hangers: "",
  deliveryDate: formatDate(Date.now()),
  deliveryStartTime: "",
  deliveryEndTime: "",
  details: "",
  totalShipmentQuantity: "",
  totalDeclaredGrossWeight: "",
  volume: "",
  customerOrderNumber: "",
  codAmount: "",
  deliveryInformation: {
    paymentMethod: "",
  },
  deliveryType: "",
  type: "",
  smsNotifications: "",
  notes: "",
};

const useOrders = () => {
  const orders = useSelector((state) => state.orders);
  const {
    selectedOrder,
    allOrders,
    pendingOrders,
    completeOrders,
    showingOrders,
    currentDateRange,
    visibleColumns,
  } = orders;
  const dispatch = useDispatch();

  // Getters
  const getOrders = (from = currentDateRange[0], to = currentDateRange[1]) => {
    dispatch(setLoading(true));

    axios
      .get(`/api/orders?deliveryDateFrom=${from}&deliveryDateTo=${to}`)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setAllOrders(res.data));
        showOrders(res.data);
        csvHandler();
      })
      .catch((err) => {
        toast.error("ERROR: ", err);
        dispatch(setLoading(false));
      });
  };

  const editOrder = (order) => {
    dispatch(setLoading(true));

    axios
      .put("/api/orders/order/edit", order)
      .then((res) => {
        getOrders();
      })
      .then(() => {
        dispatch(setLoading(false));
        closeModalHandler();
      })
      .catch((err) => {
        toast.error("ERROR: ", err);
        dispatch(setLoading(false));
      });
  };

  const cancelOrder = () => {
    if (window.confirm("Are you sure? This order will be cancelled.")) {
      dispatch(setLoading(true));
      console.log("cancelling order");
      axios
        .put("/api/orders/cancel", selectedOrder)
        .then(() => {
          dispatch(setLoading(false));
          toast.success("Order Successfully Cancelled!");
          closeModalHandler();
          getOrders();
        })
        .catch((err) => {
          toast.error("ERROR: ", err);
          dispatch(setLoading(false));
        });
    }
  };

  const getOrderTimeline = (id) => {
    axios
      .get(`/api/orders/timeline/${id}`)
      .then((res) => {
        dispatch(setTimeline(res.data));
      })
      .catch((err) => {
        console.log("getTimeLine Error: ", err);
      });
  };

  // handlers
  const showOrders = (orders) => {
    dispatch(setShowingOrders(orders));
    getPendingCreatedOrderState();
  };

  const getPendingCreatedOrderState = () => {
    const completedOrders = [];
    const ordersCopy = showingOrders.slice();
    const pendingOrders = ordersCopy.filter((item) => {
      if (item["status"] !== "DELIVERED" && item["status"] !== "FAILED") {
        return true;
      } else if (item["status"] !== "CANCELED") {
        completedOrders.push(item);
      }
      return false;
    });
    // set active and pending orders
    dispatch(setPendingOrders(pendingOrders));
    dispatch(setCompleteOrders(completedOrders));
  };

  const orderSearchHandler = (e) => {
    const searchInput = e.target.value;
    if (searchInput === "" || searchInput === " ") {
      dispatch(setShowingOrders(allOrders));
    } else {
      const filtered = orderSearching(allOrders, searchInput);
      dispatch(setShowingOrders(filtered));
    }
  };

  const dateChangeHandler = (from, to) => {
    dispatch(setDateRange([from, to]));
    getOrders(from, to);
  };

  const activePendingHandler = (filterBy) => {
    if (filterBy === "all") {
      dispatch(setShowingOrders(allOrders));
    } else if (filterBy === "pending") {
      dispatch(setShowingOrders(pendingOrders));
    } else if (filterBy === "complete") {
      dispatch(setShowingOrders(completeOrders));
    }
  };

  const visibleColumnsHandler = (tableHiddenColumnArr) => {
    const filteredColumns = TableColumns.filter(
      (elem) => !tableHiddenColumnArr.find((id) => elem.id === id)
    );
    dispatch(setVisibleColumns(filteredColumns));
  };

  const reorderColumnsHandler = (dropped) => {
    if (!dropped.destination) {
      return;
    }
    const result = visibleColumns.slice();
    const [removed] = result.splice(dropped.source.index, 1);
    result.splice(dropped.destination.index, 0, removed);
    dispatch(setVisibleColumns(result));
  };

  const columnWidthHandler = (columnId, width) => {
    let currentColumns = [...visibleColumns];
    const currentColumnIndex = currentColumns.findIndex((column) => {
      return column.id === columnId;
    });
    const columnToChange = currentColumns[currentColumnIndex];
    const changedColumn = { ...columnToChange, width };
    currentColumns.splice(currentColumnIndex, 1, changedColumn);
    dispatch(setVisibleColumns(currentColumns));
  };

  const multiSearchHandler = (searchQueryObj) => {
    const result = orderSearching(allOrders, searchQueryObj);
    dispatch(setShowingOrders(result));
  };

  const selectedOrderModalHandler = (order) => {
    getOrderTimeline(order.id);
    dispatch(setShowSelectedOrder(order));
  };

  const tableDenseHandler = () => {
    dispatch(setTableDense());
  };

  const csvHandler = () => {
    const headers = visibleColumns.map((data) => ({
      label: data.label,
      key: data.id,
    }));
    dispatch(setCsvHeaders(headers));
  };

  const closeModalHandler = () => {
    dispatch(closeModal());
    dispatch(setSelectedOrder(orderInitialState));
  };

  return {
    ...orders,
    getOrders,
    showOrders,
    editOrder,
    cancelOrder,
    selectedOrderModalHandler,
    csvHandler,
    closeModalHandler,
    dateChangeHandler,
    tableDenseHandler,
    orderSearchHandler,
    columnWidthHandler,
    multiSearchHandler,
    activePendingHandler,
    visibleColumnsHandler,
    reorderColumnsHandler,
  };
};

//   const newStatus = {
//     ORDERED: 1,
//     DELIVERED: 3,
//     CANCELED: 4,
//     FAILED: 5,
//     IN_TRANSIT: 6,
//     PICKED_UP: 7,
//     ARRIVED_IN_DC: 9,
//     OUT_FOR_DELIVERY: 10,
//     PROCESS_FOR_RETURN: 15,
//     RETURNED_TO_SHIPPER: 16,
//     HUB_TRANSFER: 17,
//     RESCHEDULED: 22,
//     REATTEMPT: 23,
//     RETURNED_TO_DC: 20,
//   };

export default useOrders;
