import { INITIALIZE, LOGIN, LOGOUT, SET_USER_SETTINGS } from "./types";

export const initialize = (data) => {
  return {
    type: INITIALIZE,
    payload: data,
  };
};

export const login = (data) => {
  return {
    type: LOGIN,
    payload: data,
  };
};

export const logout = (data) => {
  return {
    type: LOGOUT,
    payload: data,
  };
};

export const setUserSettings = (data) => {
  return {
    type: SET_USER_SETTINGS,
    payload: data,
  };
};
