import React from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import Navbar from "../components/layout/Navbar";

const MainLayout = (props) => {
  return (
    <Box sx={{ background: "#f5f5f5" }}>
      <Navbar />
      <Outlet {...props} />
    </Box>
  );
};

export default MainLayout;
