// Auth
export const INITIALIZE = "INITIALIZE";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";

// Activities ?? Better rethink these actions ??
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const SET_ACTIVITY_LOADING = "SET_ACTIVITY_LOADING";

// Orders
export const ADD_FILTER = "ADD_FILTER";
export const CLOSE_ORDER_MODAL = "CLOSE_ORDER_MODAL";
export const SET_TIMELINE = "SET_TIMELINE";
export const SET_ALL_ORDERS = "SET_ALL_ORDERS";
export const SET_DATE_RANGE = "SET_DATE_RANGE";
export const SET_COLUMN_DATA = "SET_COLUMN_DATA";
export const SET_TABLE_DENSE = "SET_TABLE_DENSE";
export const ADD_SEARCH_QUERY = "ADD_SEARCH_QUERY";
export const SET_SELECTED_ORDER = "SET_SELECTED_ORDER";
export const SET_PENDING_ORDERS = "SET_PENDING_ORDERS";
export const SET_SHOWING_ORDERS = "SET_SHOWING_ORDERS";
export const SET_COMPLETE_ORDERS = "SET_COMPLETE_ORDERS";
export const SHOW_SELECTED_ORDER = "SHOW_SELECTED_ORDER";
export const SET_VISIBLE_COLUMNS = "SET_VISIBLE_COLUMNS";
export const SET_SAVED_QUERIES = "SET_SAVED_QUERIES";
export const SET_CSV_HEADERS = "SET_CSV_HEADERS";
export const SET_LOADING = "SET_LOADING";
// ?? what the fuck is that??
export const SET_NUM_ORDERS = "SET_NUM_ORDERS";

// NewOrders
export const SET_ORDER_EDITS = "SET_ORDER_EDITS";
export const SHOW_CSVMAP_MODAL = "SHOW_CSVMAP_MODAL";
export const SET_ORDER_UPLOADS = "SET_ORDER_UPLOADS";
export const SET_FORM_IS_EDITABLE = "SET_FORM_IS_EDITABLE";
export const SET_CSV_REQUEST_PENDING = "SET_CSV_REQUEST_PENDING";
export const SHOW_CREATE_ORDER_MODAL = "SHOW_CREATE_ORDER_MODAL";
export const SET_CSV_MAPPING = "SET_CSV_MAPPING";
export const SET_USED_CSV_HEADER_NAMES = "SET_USED_CSV_HEADER_NAMES";
export const SET_CSV_MAPPING_ERRORS = "SET_CSV_MAPPING_ERRORS";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";
export const SET_CSV_HEADER_ERRORS = "SET_CSV_HEADER_ERRORS";
export const SET_CSV_ERRORS = "SET_CSV_ERRORS";
export const CLOSE_NEW_ORDER_MODAL = "CLOSE_NEW_ORDER_MODAL";

// Reports
