/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

import styles from "../../../assets/jss/material-dashboard-react/components/sidebarStyle";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const { reports } = props;

  var links = (
    <List className={classes.list}>
      {reports.map((report, key) => {
        var listItemClasses = classNames({
          [" " + classes["blue"]]: activeRoute(report.path),
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(report.path),
        });

        return (
          <NavLink
            to={report.path}
            className={classNames(classes.item)}
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              {typeof report.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: report.rtlActive,
                  })}
                >
                  {report.icon}
                </Icon>
              ) : (
                <report.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: report.rtlActive,
                  })}
                />
              )}
              <ListItemText
                primary={report.rtlActive ? report.rtlName : report.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: report.rtlActive,
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  return (
    <div style={{ width: "260px" }}>
      <Drawer
        variant="permanent"
        anchor="left"
        open
        classes={{
          paper: classNames(classes.drawerPaper),
        }}
      >
        <div className={classes.sidebarWrapper}>{links}</div>
        <div className={classes.background} />
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};
