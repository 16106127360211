/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { CSVLink } from "react-csv";
import { makeStyles } from "@mui/styles";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Tooltip, IconButton } from "@mui/material";

import GridItem from "../components/Reports/components/GridItem.js";
import GridContainer from "../components/Reports/components/GridContainer.js";
import Table from "../components/Reports/components/Table.js";
import Card from "../components/Reports/components/Card.js";
import CardHeader from "../components/Reports/components/CardHeader.js";
import CardBody from "../components/Reports/components/CardBody.js";
import CustomDatePicker from "../components/Orders/OrdersTableNav/CustomDatePicker";
import useOrders from "../hooks/useOrders.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
const useStyles = makeStyles(styles);

function TableList(props) {
  const { allOrders, getOrders } = useOrders();
  const classes = useStyles();
  const [macroChartData, setMacroChartData] = useState([]);
  const [microChartData, setMicroChartData] = useState([]);
  // const [macroGraphData, setMacroGraphData] = useState([]);
  // const [microGraphData, setMicroGraphData] = useState([]);
  const [ordersByCustomer, setOrdersByCustomer] = useState([]);
  const [ordersByDate, setOrdersByDate] = useState([]);
  const [dateSum, setDateSum] = useState([]);
  const [customerSum, setCustomerSum] = useState([]);

  useEffect(() => {
    if (allOrders.length === 0) {
      getOrders();
    }
  }, []);

  useEffect(() => {
    const macroData = generateMacroChartData();
    const microData = generateMicroChartData();
    setMacroChartData(macroData);
    setMicroChartData(microData);
  }, [props]);

  // useEffect(() => {
  //   const microGraphData = generateMicroGraphData();
  //   setMicroGraphData(microGraphData);
  // }, [microChartData]);

  // useEffect(() => {
  //   const macroGraphData = generateMacroGraphData();
  //   setMacroGraphData(macroGraphData);
  // }, [macroChartData]);

  // const generateMacroGraphData = () => {
  //   const graphData = {
  //     labels: [],
  //     series: [[], [], []],
  //   };
  //   macroChartData.forEach(row => {
  //     graphData.labels.push(row[0]);
  //     graphData.series[0].push(row[1]);
  //     graphData.series[1].push(row[2]);
  //     graphData.series[2].push(row[3]);
  //   });
  //   return graphData;
  // };

  // const generateMicroGraphData = () => {
  //   const graphData = {
  //     labels: [],
  //     series: [[], [], []],
  //   };
  //   microChartData.forEach(row => {
  //     graphData.labels.push(row[0]);
  //     graphData.series[0].push(row[1]);
  //     graphData.series[1].push(row[2]);
  //     graphData.series[2].push(row[3]);
  //   });
  //   return graphData;
  // };

  const generateMacroChartData = () => {
    const data = {};
    const history = { type: "consignee.name" };
    let sum = [0, 0, 0];

    allOrders.forEach((order) => {
      const date = order.deliveryDate;
      if (!data[date]) {
        data[date] = [0, 0, 0];
        history[date] = [order];
      } else {
        history[date].push(order);
      }
      if (order.totalShipmentQuantity) {
        data[date][0] += Number(order.totalShipmentQuantity);
        sum[0] += Number(order.totalShipmentQuantity);
      }
      if (order.bagsReturned) {
        data[date][1] += Number(order.bagsReturned);
        sum[1] += Number(order.bagsReturned);
      }
      if (order.icePacksReturned) {
        data[date][2] += Number(order.icePacksReturned);
        sum[2] += Number(order.icePacksReturned);
      }
    });
    setCustomerSum(sum);

    const keys = Object.keys(data);
    const vals = Object.values(data);
    const chartData = [];

    keys.forEach((date, i) => {
      chartData.push([date, ...vals[i]]);
    });

    setOrdersByCustomer(history);
    return chartData;
  };

  const generateMicroChartData = () => {
    const data = {};
    const history = { type: "deliveryDate" };
    let sum = [0, 0, 0];

    allOrders.forEach((order) => {
      const customer = order.consignee.name;
      if (!data[customer]) {
        data[customer] = [0, 0, 0];
        history[customer] = [order];
      } else {
        history[customer].push(order);
      }
      if (order.totalShipmentQuantity) {
        data[customer][0] += Number(order.totalShipmentQuantity);
        sum[0] += Number(order.totalShipmentQuantity);
      }
      if (order.bagsReturned) {
        data[customer][1] += Number(order.bagsReturned);
        sum[1] += Number(order.bagsReturned);
      }
      if (order.icePacksReturned) {
        data[customer][2] += Number(order.icePacksReturned);
        sum[2] += Number(order.icePacksReturned);
      }
    });
    setDateSum(sum);

    const keys = Object.keys(data);
    const vals = Object.values(data);
    const chartData = [];

    keys.forEach((date, i) => {
      chartData.push([date, ...vals[i]]);
    });

    setOrdersByDate(history);
    return chartData;
  };

  const csvData = [
    ["Date", "No of Packages", "Bags Returned", "Ice Packs Returned"],
    ...microChartData,
    ["Date", "No of Packages", "Bags Returned", "Ice Packs Returned"],
    ...macroChartData,
  ];

  return (
    <>
      <div
        style={{
          marginLeft: "-10px",
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <CustomDatePicker />
        <CSVLink
          data={csvData}
          filename={"tcorp-overview-report.csv"}
          className="ml-2"
        >
          <Tooltip title="Download CSV" aria-label="Download CSV">
            <IconButton
              varient="contained"
              color="primary"
              size="medium"
              aria-label="export-csv"
              type="button"
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </CSVLink>
      </div>
      <GridContainer
        style={{ height: "87vh", overflow: "scroll", marginTop: "15px" }}
      >
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Assets by Date</h4>
              <p className={classes.cardCategoryWhite}>
                Here you can view what resources you have had delivered and
                returned
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={[
                  "Date",
                  "No of Packages",
                  "Bags Returned",
                  "Ice Packs Returned",
                ]}
                tableData={macroChartData}
                collapseData={ordersByCustomer}
                footerData={customerSum}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Assets by Customer</h4>
              <p className={classes.cardCategoryWhite}>
                Here you can view what resources you have had delivered and
                returned
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={[
                  "Name",
                  "No of Packages",
                  "Bags Returned",
                  "Ice Packs Returned",
                ]}
                tableData={microChartData}
                collapseData={ordersByDate}
                footerData={dateSum}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default TableList;
