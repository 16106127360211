import { format } from "date-fns";

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string[0].toUpperCase() + string.slice(1);
  }
};

export const formatDate = (date, dateFormat = "yyyy-MM-dd") => {
  return format(date, dateFormat);
};

export const formatTime = (time) => {
  return format(Date.parse(`01/01/2011 ${time}`), "HH:mm");
};

export const formatTimeAndDate = (date, dateFormat = "yyyy-MM-dd HH:mm") => {
  const d = format(Date.parse(date), dateFormat);
  return d;
};
