import React from "react";
import loginBg from "../assets/images/dubai-skyline.jpg";
import Logo from "../components/layout/Logo";
import Footer from "../components/layout/Footer";

import { Box, Card, CardContent, Container, Typography } from "@mui/material";

const LoginLayout = ({ children }) => {
  return (
    <Box
      sx={{
        background: `url(${loginBg}) no-repeat`,
        backgroundSize: "100% 100%",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        zIndex: "-1",
      }}
    >
      <Box
        sx={{
          minHeight: "100vh",
          zIndex: "1",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Logo />
                </Box>
                <Box>
                  <Typography variant="h5">Portal Login</Typography>
                </Box>
                {children}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: "#00000099",
          zIndex: "0",
        }}
      ></div>
      <Footer />
    </Box>
  );
};

export default LoginLayout;
