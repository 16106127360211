import React from "react";
import { DateRangePicker } from "rsuite";
import { add, format } from "date-fns";

// import DateRangeButtons from "./DateRangeButtons";
import useOrders from "../../../hooks/useOrders";
import "../Orders.css";
import "rsuite/dist/rsuite.min.css";

const CustomDatePicker = (props) => {
  const { currentDateRange, dateChangeHandler } = useOrders();

  const handleDateRange = (range) => {
    if (range !== null) {
      const from = range[0] ? format(range[0], "yyyy-MM-dd") : null;
      const to = range[1] ? format(range[1], "yyyy-MM-dd") : null;
      dateChangeHandler(from, to);
    }
  };
  return (
    <>
      <DateRangePicker
        appearance="default"
        onClean={() => window.location.reload()}
        style={{
          width: "230px",
        }}
        placeholder={`${currentDateRange[0]}~${currentDateRange[1]}`}
        id="orders_range"
        onChange={(range) => handleDateRange(range)}
        ranges={[
          {
            label: "Yesterday",
            value: [
              add(new Date(), {
                days: -1,
              }),
              add(new Date(), {
                days: -1,
              }),
            ],
          },
          {
            label: "Today",
            value: [new Date(), new Date()],
          },
          {
            label: "Tomorrow",
            value: [
              add(new Date(), {
                days: 1,
              }),
              add(new Date(), {
                days: 1,
              }),
            ],
          },
          {
            label: "Next 7 days",
            value: [
              Date.now(),
              add(new Date(), {
                days: 7,
              }),
            ],
          },
        ]}
      />
    </>
  );
};

export default CustomDatePicker;
