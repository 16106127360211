import { formatDate } from "../../helpers/index";
import {
  CLOSE_NEW_ORDER_MODAL,
  SET_ORDER_EDITS,
  SET_ORDER_UPLOADS,
  SHOW_CSVMAP_MODAL,
  SET_FORM_IS_EDITABLE,
  SET_CSV_REQUEST_PENDING,
  SHOW_CREATE_ORDER_MODAL,
  SET_CSV_MAPPING,
  SET_CSV_MAPPING_ERRORS,
  SET_USED_CSV_HEADER_NAMES,
  SET_FORM_ERRORS,
  SET_CSV_ERRORS,
} from "../actions/types";

const initialState = {
  editedOrder: {
    id: "",
    consignee: {
      name: "",
      location: {
        contactPhone: "",
        addressLine1: "",
        city: "",
        countryId: "224",
      },
    },
    shipFrom: {
      name: "",
      contactPhone: "",
      addressLine1: "",
      city: "",
      district: "",
      countryCode: "224",
    },
    hangers: "0",
    deliveryDate: formatDate(Date.now()),
    deliveryStartTime: "07:00",
    deliveryEndTime: "18:00",
    details: "",
    totalShipmentQuantity: "1",
    totalDeclaredGrossWeight: "",
    volume: "",
    customerOrderNumber: "",
    codAmount: "",
    deliveryInformation: {
      paymentMethod: "",
    },
    deliveryType: "STANDARD", //[DELIVERY, COLLECTION]
    type: "DELIVERY", //["STANDARD", "EXPRESS"]
    smsNotifications: true,
    notes: "",
  },
  uploads: [],
  customerHeaders: [],
  csvMappingShowModal: false,
  createShow: false,
  csvRequestPending: false,
  formIsEditable: false,
  csvMapping: {
    "consignee.name": "",
    "consignee.location.contactPhone": "",
    "consignee.location.addressLine1": "",
    "consignee.location.city": "",
    "consignee.location.district": "",
    "consignee.location.countryId": "",
    totalShipmentQuantity: "",
    details: "",
    notes: "",
    codAmount: "",
    deliveryDate: "",
    deliveryStartTime: "",
    deliveryEndTime: "",
    awb: "",
    smsNotifications: "",
    "deliveryInformation.paymentMethod": "",
    customerOrderNumber: "",
    totalDeclaredGrossWeight: "",
    volume: "",
    deliveryType: "",
  },
  usedCsvHeaderNames: [],
  csvMappingErrors: {},
  uploadErrors: [],
  formErrors: {},
  csvErrorData: "",
  csvErrors: [],
};

export default function newOrderReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ORDER_EDITS:
      return {
        ...state,
        editedOrder: action.payload,
      };

    case SET_FORM_IS_EDITABLE:
      return {
        ...state,
        formIsEditable: !state.formIsEditable,
      };

    case SET_ORDER_UPLOADS:
      return {
        ...state,
        uploads: action.payload,
        loading: false,
      };
    /// DOES THIS NEED TO EXIST????
    case SET_CSV_REQUEST_PENDING:
      return {
        ...state,
        csvRequestPending: action.payload,
      };

    case SET_CSV_MAPPING:
      return {
        ...state,
        csvMapping:
          action.payload === null ? initialState.csvMapping : action.payload,
      };

    case SET_USED_CSV_HEADER_NAMES:
      return {
        ...state,
        usedCsvHeaderNames:
          action.payload === null
            ? initialState.usedCsvHeaderNames
            : action.payload,
      };

    case SHOW_CSVMAP_MODAL:
      return {
        ...state,
        csvMappingShowModal: true,
        customerHeaders: action.payload,
        // orderData: action.orderData,
      };

    case SET_CSV_ERRORS:
      return {
        ...state,
        csvErrors: [...action.payload.error],
        csvErrorData: action.payload.csvErrorData,
      };

    case SET_CSV_MAPPING_ERRORS:
      return {
        ...state,
        csvMappingErrors: true,
      };

    case SHOW_CREATE_ORDER_MODAL:
      return {
        ...state,
        createShow: true,
      };

    case SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload,
      };

    case CLOSE_NEW_ORDER_MODAL:
      return {
        ...state,
        csvMappingShowModal: false,
        createShow: false,
        showSelectedOrderModal: false,
        formIsEditable: false,
        editedOrder: initialState.editedOrder,
        formErrors: {},
      };

    default:
      return state;
  }
}
