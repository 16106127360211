import {
  ADD_FILTER,
  CLOSE_ORDER_MODAL,
  SET_TIMELINE,
  SET_ALL_ORDERS,
  SET_DATE_RANGE,
  SET_COLUMN_DATA,
  SET_TABLE_DENSE,
  ADD_SEARCH_QUERY,
  SET_SELECTED_ORDER,
  SET_PENDING_ORDERS,
  SET_SHOWING_ORDERS,
  SET_COMPLETE_ORDERS,
  SET_VISIBLE_COLUMNS,
  SHOW_SELECTED_ORDER,
  SET_SAVED_QUERIES,
  SET_LOADING,
  SET_CSV_HEADERS,
} from "./types";

export const setLoading = (data) => {
  return {
    type: SET_LOADING,
    payload: data,
  };
};

export const setAllOrders = (data) => {
  return {
    type: SET_ALL_ORDERS,
    payload: data,
  };
};

export const setColumnData = (data) => {
  return {
    type: SET_COLUMN_DATA,
    payload: data,
  };
};

export const setPendingOrders = (data) => {
  return {
    type: SET_PENDING_ORDERS,
    payload: data,
  };
};

export const setCompleteOrders = (data) => {
  return {
    type: SET_COMPLETE_ORDERS,
    payload: data,
  };
};

export const setShowingOrders = (data) => {
  return {
    type: SET_SHOWING_ORDERS,
    payload: data,
  };
};

export const setVisibleColumns = (data) => {
  return {
    type: SET_VISIBLE_COLUMNS,
    payload: data,
  };
};

export const setSavedQueries = (data) => {
  return {
    type: SET_SAVED_QUERIES,
    payload: data,
  };
};

export const setDateRange = (data) => {
  return {
    type: SET_DATE_RANGE,
    payload: data,
  };
};

export const setTableDense = () => {
  return {
    type: SET_TABLE_DENSE,
  };
};

export const setTimeline = (data) => {
  return {
    type: SET_TIMELINE,
    payload: data,
  };
};

export const updateSearchQueries = (query) => {
  return {
    type: ADD_SEARCH_QUERY,
    payload: query,
  };
};

export const addSearchFilter = (query) => {
  return {
    type: ADD_FILTER,
    payload: query,
  };
};

export const setShowSelectedOrder = (data) => {
  return {
    type: SHOW_SELECTED_ORDER,
    payload: data,
  };
};

export const setSelectedOrder = (order) => {
  return {
    type: SET_SELECTED_ORDER,
    payload: order,
  };
};

export const setCsvHeaders = (headers) => {
  return {
    type: SET_CSV_HEADERS,
    payload: headers,
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_ORDER_MODAL,
  };
};
