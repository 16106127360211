import React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterBox>
      <small>Flow Marketing &copy;&nbsp;{new Date().getFullYear()}&nbsp;</small>
      <small>
        <StyledLink to={"privacy-policy"}>Privacy Policy |</StyledLink>
      </small>
      <small>
        <StyledLink to={"privacy-policy"}>
          &nbsp;{"Terms & Conditions"}
        </StyledLink>
      </small>
    </FooterBox>
  );
};

const FooterBox = styled("div")`
  width: 100%;
  height: 30px;
  background: black;
  display: flex;
  align-items: center;
  color: #444;
  padding-left: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #444;
`;

export default Footer;
